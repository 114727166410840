import React, { Component } from 'react';
import './Plans.css';
import ABNAmro from '../../assets/images/with_us/ABN_Amro.png';
import PayPal from '../../assets/images/with_us/PayPal.png';
import BNPParibasBlack from '../../assets/images/with_us/BNP_Paribas_Black.png';
import CGD from '../../assets/images/with_us/CGD.png';
import Nordea from '../../assets/images/with_us/Nordea.png';
import sopraBlack from '../../assets/images/with_us/Sopra_Black.png';
import Faq from './Faq';
import TabListView from './TabListView';
import { StateContext } from '../Store/state-context';

class Plans extends Component{

  static contextType = StateContext;

  render(){
    let bannerPadding = "";
 
    if(this.context.isBanner === true){
      bannerPadding = "banner-padding-plans"
    }
    else{
      bannerPadding = ""
    }
    return(
      <div className="main-section main-body-bg plans-main">
        <div className={`banner-text ${bannerPadding}`}>
          <div className="width-1200">
            <div className="row">
              <div className="col-md-12">
                <h1 className="h1-basic01">Plans that<br/>scale with<br/>your business</h1>
              </div>
            </div>
          </div>
        </div>

				<TabListView />

				<div className="availability-link hide">
					<div className="width-1200">
						<p>* Only available in select markets. Check availability <a href="#/">here</a>.</p>
					</div>
				</div>
				<div className="availability-link hide">
					<div className="width-1200">
						<h5 className="h5-basic01">Who’s building with us</h5>
						<ul>
							<li><img src={ABNAmro} alt="ABNAmro"/></li>
							<li><img src={PayPal} alt="PayPal"/></li>
							<li><img src={BNPParibasBlack} alt="BNPParibasBlack"/></li>
							<li><img src={CGD} alt="CGD"/></li>
							<li><img src={Nordea} alt="Nordea"/></li>
							<li><img src={sopraBlack} alt="sopraBlack"/></li>
						</ul>
					</div>
				</div>
			</div>
    );
  }
}
export default Plans;