import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class UserCaseDropdown extends Component{
  render(){
    return(
      <div className="full-dropdown">
        <div className="inner-full-dropdown">
          <div className="menu-drop-down">
            <div className="row m-0">
              <div className="col-md-4">
                <h3 className="h3-basic01">Use cases</h3>
                <p className="menu-para">Our categorization is key when it comes to understanding open banking- & PSD2-data and bringing true value to your use case.<br/><br/>Let’s make it happen together!</p>
              </div>
              <div className="col-md-4">
                <h4 className="h4-basic01">Solving real problems for different industries</h4>
                <p className="label-menu">
                  <Link to="/use-cases#consumer-lending" className="open_link link-color">
                    <strong>Consumer lending</strong>
                    Reduce manual touch-points & risk
                  </Link>
                </p>
                <p className="label-menu">
                  <Link to="/use-cases#business-landing" className="open_link link-color">
                    <strong>Business lending</strong>
                    Up to date company performance
                  </Link>
                </p>
                <p className="label-menu">
                  <Link to="/use-cases#money-transfer" className="open_link link-color">
                    <strong>Money transfer</strong>
                    Automate the KYC/AML-process
                  </Link>
                </p>
              </div>
              <div className="col-md-4 pt-45">
                <p className="label-menu">
                  <Link to="/use-cases#online-gambling" className="open_link link-color">
                    <strong>Online gambling</strong>
                    Source of income & wealth
                  </Link>
                </p>
                <p className="label-menu">
                  <Link to="/use-cases#auditing" className="open_link link-color">
                    <strong>Auditing</strong>
                    Efficient corporate governance
                  </Link>
                </p>
                <p className="label-menu">
                  <Link to="/use-cases#insurance" className="open_link link-color">
                    <strong>Insurance</strong>
                    Proof-of-purchase & insurance history
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default UserCaseDropdown;