import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

class AboutDropdown extends Component{
  render(){
    return(
      <div className="full-dropdown">
        <div className="inner-full-dropdown">
          <div className="menu-drop-down">
            <div className="row m-0">
              <div className="col-md-4">
                <h3 className="h3-basic01">About Kreditz</h3>
                <p className="menu-para">We know that it’s a bold statement to say that we are the brains of open banking- & PSD2-data.<br/><br/>That’s why we invite you to learn more about our amazing team, experienced board & seasoned investors.</p>
                <Link to="/bookings" className="contact-us-btn">Book a demo</Link>
              </div>
              <div className="col-md-4">
                <h4 className="h4-basic01"><Link to="/about" className="open_link link-color">Company</Link></h4>
                <p className="label-menu">
                  <Link to="/about#about-kreditz" className="open_link link-color">
                    <strong>About Kreditz</strong>
                    Learn about us & our mission
                  </Link>
                </p>
                <p className="label-menu">
                  <Link to="/about#team-members" className="open_link link-color">
                    <strong>The team</strong>
                    Say hi to our founders & staff members
                  </Link>
                </p>
                <p className="label-menu">
                  <Link to="/about#board-members" className="open_link link-color">
                    <strong>The board</strong>
                    Meet the chairwoman & board members
                  </Link>
                </p>
                <p className="label-menu">
                  <Link to="/about#investors" className="open_link link-color">
                    <strong>The investors</strong>
                    Our trusted financial backers
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AboutDropdown;