import React, { Component } from 'react'
import './TermsCondition.css';

class AccountInformationService extends Component{
  render(){
    return(
      <div className="main-section  main-body-bg">
        <div className="term-and-condition">
          <div className="width-1200">
           <div className="row inner-terms-container">
              <section className="col-md-12">
                <h1 className="h1-basic01 tc-head">Kreditz kontoinformationstjänster – information om behandling av personuppgifter</h1>
              </section>
              <section className="col-md-12">
                <h3>Allmänt</h3>
                <p>
                  Nedan beskrivs hur Kreditz AB (”<b>Kreditz</b>”, ”<b>vi</b>”, ”<b>vår</b>”, ”<b>oss</b>”) behandlar dina personuppgifter i samband med att Kreditz tillhandahåller kontoinformationstjänster och tillhörande tjänster (gemensamt ”<b>Tjänsterna</b>”).
                </p>
                <p>
                  Tjänsterna förmedlas till dig via någon av våra samarbetspartners (”<b>Partner</b>”) i samband med att respektive Partner tillhandahåller egna tjänster (”<b>Partnertjänster</b>”) till dig.
                </p>
                <p>
                  Informationen nedan beskriver hur Kreditz behandlar dina personuppgifter. Respektive Partner behandlar dina personuppgifter på eget personuppgiftsansvar. Om du vill veta mer om hur en Partner behandlar dina personuppgifter måste du vända dig direkt till Partnern ifråga.
                </p>
              </section>
              <section className="col-md-12">
                <h3>Kreditz är personuppgiftsansvarig</h3>
                <p>
                  Kreditz är personuppgiftsansvarig för behandling av dina personuppgifter i samband med att vi tillhandahåller Tjänsterna till dig. Du når oss enligt följande:
                </p>
                <p>
                  Kreditz AB
                </p>
                <p>
                  Vendevägen 87
                </p>
                <p>
                  182 32 Danderyd
                </p>
                <p>
                  Email: info@Kreditz.com
                </p>
              </section>
              <section className="col-md-12">
                <h3>Hur samlar vi in dina uppgifter?</h3>
                <p>
                  <u>Från Partners</u>
                </p>
                <p>
                  Vår initiala insamling av dina personuppgifter sker genom att vi via en Partnertjänst får de uppgifter som krävs för att vi ska kunna tillhandahålla respektive Kreditztjänst till dig. De uppgifter det handlar om är grundläggande identitetsuppgifter (till exempel namn och personnummer).
                </p>
                <p>
                  <u>Från dig själv</u>
                </p>
                <p>
                  Vi inhämtar också vissa uppgifter direkt från dig själv. Det rör sig då dels om dina uppgifter som behövs för kommunikation med berörd bank eller annan verksamhetsutövare, dels vissa identitets- och adressuppgifter som vi kan behöva för genomförande av den aktuella Tjänsterna. Vi kan också inhämta andra uppgifter från dig som krävs för att vi ska kunna fullgöra skyldigheter enligt lag (till exempel sanktionsregler).
                </p>
                <p>
                  <u>Från din bank</u>
                </p>
                <p>
                  Tjänsternas genomförande förutsätter också att vi hämtar uppgifter från din bank i form av uppgifter om bankkonton, kontotransaktioner och annan finansiell information. Observera att vi inte inhämtar denna typ av uppgifter utan ditt uttryckliga medgivande.
                </p>
                <p>
                  <u>Från leverantörer av identifieringstjänster m.m.</u>
                </p>
                <p>
                  Slutligen kan vi komma att inhämta och verifiera grundläggande identitetsuppgifter från leverantörer av lösningar för digitala signaturer (till exempel BankID) eller motsvarande. Vi kan också komma att från externa leverantörer inhämta sådana uppgifter som krävs för att vi ska kunna fullgöra skyldigheter enligt lag (till exempel penningtvättsregler).
                </p>
              </section>
              <section className="col-md-12">
                <h3>Vilka uppgifter om dig behandlar vi och varför?</h3>
                <p>
                  I samband med att vi tillhandahåller Tjänsterna behandlar vi dina personuppgifter för att uppfylla våra åtaganden i de avtal vi ingått med dig, för att kunna tillhandahålla en Kreditztjänst till dig, för att kunna ge dig den service som du kan förvänta dig och, i förekommande fall, för att uppfylla våra lagstadgade skyldigheter eller för att med stöd av vårt berättigade intresse försvara våra rättsliga intressen och utveckla Tjänsterna. Vi sparar inte dina personuppgifter längre än vad som är nödvändigt för att uppfylla syftet med behandlingen.
                </p>
                <p>
                  De personuppgifter som vi behandlar är av varierande slag beroende på vilken Tjänst vi tillhandahåller dig. Nedan finns en sammanfattande beskrivning av vilka uppgifter vi behandlar i respektive Tjänst.
                </p>
                <p>
                  <u>Kontoinformationstjänst</u>
                </p>
                <p>
                  I samband med att vi tillhandahåller dig en kontoinformationstjänst, både när det gäller en enstaka tjänst och tjänst som utförs inom ramen för ett ramavtal mellan dig och Kreditz, behandlar vi uppgifter såsom ditt namn, kontaktuppgifter, personnummer, kontoinformation såsom exempelvis kontonummer och -historik, kredituppgifter, information om köptransaktioner (belopp, tid, typ av transaktion och eventuell vara och/eller köpställe), annan finansiell information som härrör från dina konton, positioneringsdata och IP-adress. Vi behandlar vidare dessa uppgifter för att genomföra de analyser av dina uppgifter som vi gör på uppdrag av vår Partner för Partnertjänsten. Denna behandling görs på uppdrag av våra Partners i syfte att analysera din ekonomi och risker kopplat till den som våra Partners behöver som beslutsunderlag i sina interna processer. Vi behandlar vidare dessa uppgifter för kommunikation med berörd bank.
                </p>
                <p>
                  <u>Krav enligt penningtvättsregler m.m.</u>
                </p>
                <p>
                  Vissa av omnämnda uppgifter behandlas också i syfte att uppfylla våra lagstadgade skyldigheter, till exempel för att uppfylla våra skyldigheter för sanktionsscreening. För uppfyllande av sådana skyldigheter kan vi också komma att behandla uppgifter för bestämmande av om du ska betraktas som en person i politiskt utsatt ställning (s.k. PEP) och uppgifter som behövs för sanktionslistkontroller.
                </p>
              </section>
              <section className="col-md-12">
                <h3>Hur vi behandlar dina uppgifter och rättslig grund för behandling</h3>
                <p>
                  Kreditz behandlar dina personuppgifter för att leverera Tjänsterna till dig, vilket sker med avtalet mellan dig och oss som rättslig grund. Detta avser samtliga uppgifter vi behandlar om dig med undantag för uppgifter vi behandlar för att uppfylla krav enligt penningtvättsregler.
                </p>
                <p>
                  Vi behandlar också dina uppgifter för att utveckla och anpassa Tjänsterna och dess funktioner. Uppgifterna kan också ligga till grund för produkt- och kundanalyser, statistik och affärs- och metodutveckling. Vidare kan uppgifter behandlas i syfte att säkerställa att vi har utfört Tjänsterna korrekt. Behandling av dessa uppgiftskategorier sker med berättigat intresse som rättslig grund.
                </p>
                <p>
                Slutligen kan dina uppgifter också komma att behandlas inom ramen för våra skyldigheter att iaktta gällande penningtvättsregler eller för att motverka bedrägerier och säkerhetsbrister. Dessa skyldigheter utgör den rättsliga grunden för denna behandling.
                </p>
              </section>
              <section className="col-md-12">
                <h3>Hur länge sparar vi dina uppgifter?</h3>
                <p>
                  De uppgifter som vi samlar för Tjänsternas tillhandahållande sparas så länge de behövs för de ändamål för vilka uppgifterna samlades in. Därefter raderas eller anonymiseras uppgifterna. Många uppgifter raderas i allmänhet inom ett år från det att vi har fullgjort våra åtaganden i förhållande till respektive Tjänsternas, men vissa uppgifter kan bevaras längre, till exempel uppgifter som behövs för en juridisk process eller uppgifter som vi enligt gällande penningtvättsregler måste spara i 5 år.
                </p>
                <p>
                  <u>Identitetsuppgifter</u>
                </p>
                <p>
                  Identitetsuppgifter bevaras i upp till ett år efter att det avtal som ligger till grund för tillhandahållande av Tjänsterna har avslutats. I sådana fall där vi till följd av andra omständigheter behöver bevara uppgifter längre, till exempel uppgifter som behövs för en juridisk process eller uppgifter som vi enligt gällande penningtvättsregler måste spara i 5 år, kan identitetsuppgifter sparas under en motsvarande period.
                </p>
                <p>
                  <u>Transaktionsuppgifter</u>
                </p>
                <p>
                  När vi tillhandahåller kontoinformationstjänst till dig utför vi tjänsten varefter den inhämtade informationen tillgängliggörs till av dig anvisad Partner. Efter att vi har lämnat information till Partnern används informationen för att i efterhand kunna verifiera att vi har genomfört tjänsten korrekt och för att kunna genomföra analyser. Vi behåller informationen i upp till 1 år beräknat från den dagen när tjänsten utfördes, för dessa ändamål. Vänligen notera att data även kan komma att raderas efter en kortare period.
                </p>
                <p>
                  <u>Tvister etc.</u>
                </p>
                <p>
                  I enstaka fall, till exempel om uppgifterna är relevanta i en tvist, kan vi behöva bevara uppgifter längre än ett år för att försvara eller göra gällande våra rättsliga intressen.
                </p>
              </section>
              <section className="col-md-12">
                <h3>Med vem delar vi dina uppgifter?</h3>
                <p>
                  Dina personuppgifter delas i första hand med den eller de Partners vars Partnertjänster du använder och till vilka du har anvisat oss att tillgängliggöra uppgifterna till. Informationen tillgängliggörs i samband med att vi tillhandahåller tjänsten till dig och i anslutning till din begäran att genomföra denna. De uppgifter vi delar med Partners är endast sådana uppgifter som krävs för att vi ska kunna fullgöra den Tjänst du har bett oss att utföra.
                </p>
                <p>
                  Dina uppgifter kan också komma att delas med din bank i samband med att du begär att vi ska tillhandahålla dig en Tjänst. De inloggningsuppgifter du har delat med oss lämnas endast ut till din bank och endast i samband med att respektive Tjänst utförs.
                </p>
                <p>
                Slutligen kan dina uppgifter komma att lämnas ut till rättsvårdande myndigheter inom ramen för våra skyldigheter enligt gällande penningtvättsregler.
                </p>
                <p>
                  Vi använder också mjukvaru- och datalagringsleverantörer som kan komma att behandla uppgifter om dig. Dessa leverantörer får dock endast behandla uppgifterna för vår räkning och enligt våra instruktioner, och uppgifterna får inte lämnas ut till någon annan än Kreditz.
                </p>
              </section>
              <section className="col-md-12">
                <h3>Var behandlar vi dina uppgifter?</h3>
                <p>
                  Vi strävar alltid efter att behandla dina personuppgifter inom EU/EES. I vissa situationer, såsom när vi delar din information med till exempel en IT-leverantör med verksamhet utanför EU/EES, kan dina personuppgifter dock komma att behandlas utanför EU/EES. Om och när dina uppgifter behandlas utanför EU/EES säkerställer vi att det föreligger en adekvat skyddsnivå och att lämpliga skyddsåtgärder vidtas (till exempel genom användning av EU-kommissionens standardavtalsklausuler).
                </p>
              </section>
              <section className="col-md-12">
                <h3>Dina rättigheter som registrerad</h3>
                <p>Som registrerad har du vissa rättigheter i förhållande till behandling av dina personuppgifter. Om du vill utöva någon av dem, vänligen kontakta oss via info@Kreditz.com.</p>
                <ul>
                  <li className="terms-li">
                    Rätt till tillgång: Du har rätt att få information om vilka personuppgifter vi behandlar om dig, inklusive ändamålet för behandlingen samt den rättsliga grunden för behandlingen.
                  </li>
                  <li className="terms-li">
                    Rätt till korrigering/rättelse: Om du anser att vi behandlar felaktiga personuppgifter om dig kan du be oss om att rätta till dem.
                  </li>
                  <li className="terms-li">
                    Rätt till begränsning av behandlingen: Du kan begära att vi begränsar behandlingen av dina personuppgifter. Det kan till exempel vara relevant om vi har felaktiga uppgifter om dig och du inte vill att behandlingen ska fortsätta förrän vi har rättat uppgifterna.
                  </li>
                  <li className="terms-li">
                    Rätt till radering / rätt att glömmas bort: Du kan begära att vi tar bort dina personuppgifter. Även om vi kommer att tillmötesgå en sådan begäran i den utsträckning som krävs enligt gällande lag, vänligen notera att vi trots din begäran kan komma att fortsätta behandlingen av vissa uppgifter (såsom uppgifter som vi behöver behålla för att skydda våra rättsliga intressen eller som vi enligt lag är skyldiga att bevara).
                  </li>
                  <li className="terms-li">
                    Rätt att invända: I samband med behandling av personuppgifter baserat på berättigat intresse har du rätt att invända mot användningen av dina personuppgifter. Om dina integritetsintressen uppväger våra intressen för att behandla vissa data, kommer vi att sluta behandla sådana uppgifter.
                  </li>
                  <li className="terms-li">
                    Rätt till dataportabilitet: Du kan även ha rätt att få tillgång till de personuppgifter som du har tillhandahållit oss i ett strukturerat, allmänt accepterat och maskinläsbart format, samt rätt att överföra uppgifterna till en annan personuppgiftsansvarig.
                  </li>
                </ul>
              </section>
              <section className="col-md-12">
                <h3>Vill du veta mer?</h3>
                <p>
                  Om du har några frågor om vår behandling av dina personuppgifter, vänligen inte tveka att kontakta oss via info@Kreditz.com.
                </p>
              </section>
              <section className="col-md-12">
                <h3>Om du inte är nöjd med oss</h3>
                <p>
                  Om du inte är nöjd med hur vi behandlar dina personuppgifter är du välkommen att kontakta vårt dataskyddsombud via privacy@Kreditz.com. Du kan också kontakta den svenska Integritetsskyddsmyndigheten (<u>Integritetsskyddsmyndigheten</u>, <u>www.imy.se</u>, imy@imy.se).
                </p>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AccountInformationService;