import React, { Component } from "react";
import "./ContactUs.css";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLoaded: false,
    };
  }

  loadHubSpotForm() {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "5658417",
          formId: "64d0f309-63ad-4652-b217-a00ace1a6582",
          target: ".hubspot-form-container",
          css: "",
          cssClass: "hubspot-form-container",
        });

        this.setState({ formLoaded: true });
      } else {
        this.setState({ formLoaded: false });
      }
    };
  }

  componentDidMount() {
    this.loadHubSpotForm();
  }

  render() {

    let bannerPadding = "";

    if (this.context.isBanner === true) {
      bannerPadding = "banner-padding-contact"
    }
    else {
      bannerPadding = ""
    }

    const hubspotFormContainer = this.state.formLoaded ? (
      <div className="hubspot-form-container" data-cookieconsent="marketing"></div>
    ) : (
      <p className="error-message">
        Error loading the form. Please try again later.
      </p>
    );

    return (
      <div className="main-section main-body-bg">
        <div className="tab-list-view verification-tab-list-view">
          <div className="width-1200">
            <div className="tab-list-details verification-tab-list-details">
              <div className={`width-1200 ${bannerPadding}`} style={{ margin: 0 }}>
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="h1-basic01">
                      Contact
                      <br />
                      sales
                    </h1>
                    <h6 className="h6-basic03 mt-40">
                      Interested to talk more about how we can build the
                      future of financial services?
                      <br /> We’re happy to hear from you. Fill in the form
                      below and we’ll be in touch soon.
                    </h6>
                    <h6 className="h6-basic03 mt-40">
                      Please note that this email and form are only for
                      business inquiries. For all questions
                      <br />
                      regarding our app, please email{" "}
                      <a href="info@kreditz.com" className="kreditz-email">
                        info@kreditz.com
                      </a>
                    </h6>
                  </div>
                </div>
              </div>
              {hubspotFormContainer}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactUs;