import React, { Component } from 'react';
import GetInTouchBanner from './GetInTouchBanner';
import base from '../../assets/images/base.svg';
import base_res from '../../assets/images/base_sp.svg';
import FunnelSet from './FunnelSet';
import WorkAccuracy from './WorkAccuracy';
import TestimonialSet from './TestimonialSet';
import RoleEcosystem from './RoleEcosystem';
import './style.css';

class InformationSection extends Component {
  render() {
    return (
      <div className="main-section">
        <GetInTouchBanner />
        {/* <div className="color-full-base">
          <img className="img_pc" src={base} alt="base" width="100%"/>
          <img className="img_sp" src={base_res} alt="base" width="100%"/>
        </div> */}
        {/* <FunnelSet/> */}
        {/* <WorkAccuracy/> */}
        {/* <TestimonialSet/> */}
        <RoleEcosystem />
      </div>
    );
  }
}
export default InformationSection;