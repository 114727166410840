import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import queryString from "query-string";

import "./CorporateProduct.css";
import arrowRightGraySmoll from "../../assets/images/arrow_right_gray_smoll.png";
import verificationMockup from "../../assets/images/verification_mockup.png";
import incomeVerification from "../../assets/images/customer_certificate/income_verification.png";
import spendingBehavior from "../../assets/images/customer_certificate/spending_behavior.png";
import assetsAccumultion from "../../assets/images/customer_certificate/assets_accumultion.png";
import debtInvolvement from "../../assets/images/customer_certificate/debt_involvement.png";
import creditDecisioning from "../../assets/images/customer_certificate/credit_decisioning.png";
import customProduct from "../../assets/images/customer_certificate/custom_product.png";
import { StateContext } from '../Store/state-context';

class CorporateProduct extends Component {
  constructor() {
    super();
    this.state = { tabIndex: 0 };
  }

  static contextType = StateContext;

  componentDidMount() {
    const para = queryString.parse(this.props.location.search);
    this.setState({ tabIndex: parseInt(para.tab) - 1 });
  }

  render() {
    let bannerPadding = "";

    if (this.context.isBanner === true) {
      bannerPadding = "banner-padding-products"
    }
    else {
      bannerPadding = ""
    }
    return (
      <div className="main-section main-body-bg">
        <div className="tab-list-view verification-tab-list-view">
          <div className="width-1200">
            <Tabs
              defaultFocus={true}
              selectedTabClassName="tab-active-border"
              selectedIndex={this.state.tabIndex || 0}
              onSelect={(tabIndex) => this.setState({ tabIndex })}
            >
              <TabList className={`prods-tabs ${bannerPadding}`}>
                <Tab
                  className="corporate-revenue"
                  onClick={() => this.setState({ tabIndex: 0 })}
                >
                  <Link to="/corporate-product?tab=1">Business revenue</Link>
                </Tab>

                <Tab
                  className="corporate-spending"
                  onClick={() => this.setState({ tabIndex: 1 })}
                >
                  <Link to="/corporate-product?tab=2">Business</Link>
                </Tab>
                <Tab
                  className="corporate-assets"
                  onClick={() => this.setState({ tabIndex: 2 })}
                >
                  <Link to="/corporate-product?tab=3">Business assets</Link>
                </Tab>
                <Tab
                  className="corporate-debt"
                  onClick={() => this.setState({ tabIndex: 3 })}
                >
                  <Link to="/corporate-product?tab=4">Business debt</Link>
                </Tab>
                <Tab
                  className="credit-decisioning02"
                  onClick={() => this.setState({ tabIndex: 4 })}
                >
                  <Link to="/corporate-product?tab=5">Credit decisioning</Link>
                </Tab>
                <Tab
                  className="custom-products01"
                  onClick={() => this.setState({ tabIndex: 5 })}
                >
                  <Link to="/corporate-product?tab=6">Custom products</Link>
                </Tab>
              </TabList>

              <div className="tab-list-details verification-tab-list-details">
                <TabPanel>
                  <div id="corporate-revenue" className="">
                    <div className="width-1200">
                      <div className="row">
                        <div className="col-md-12">
                          <h1 className="h1-basic01">
                            Business
                            <br />
                            revenue
                          </h1>
                          <h3 className="h3-basic02">
                            Business Account Certificate
                          </h3>
                          <h6 className="h6-basic03">
                            Look into the <i>Business revenue</i> and see trends
                            for the past 12 months to gain valuable insight of
                            the current performance of the business
                          </h6>
                        </div>
                      </div>
                      <div className="row custom-insights">
                        <div className="col-md-5">
                          <h2 className="h2-basic02">
                            Gives you the following insights
                          </h2>
                          <ul>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Business turnover past 3, 6 and 12 months
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Identify the largest incoming payments
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Identify incoming tax payments
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              And more
                            </li>
                          </ul>
                          <Link to="/contact-us">
                            <button className="defaultBtn white-bg">Get in touch</button>
                          </Link>
                        </div>
                        <div className="col-md-7">
                          <figure>
                            <img
                              src={incomeVerification}
                              alt="incomeVerification"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>

                <TabPanel>
                  <div id="corporate-spending" className="">
                    <div className="width-1200">
                      <div className="row">
                        <div className="col-md-12">
                          <h1 className="h1-basic01">
                            Business
                            <br />
                            spending
                          </h1>
                          <h3 className="h3-basic02">
                            Business Account Certificate
                          </h3>
                          <h6 className="h6-basic03">
                            Keep track of how the business is spending their
                            money with the <i>Business spending</i> module.
                            Identify suppliers and salary cost as well as
                            payments to credit institutions and tax authorities.
                          </h6>
                        </div>
                      </div>
                      <div className="row custom-insights">
                        <div className="col-md-5">
                          <h2 className="h2-basic02">
                            Gives you the following insights
                          </h2>
                          <ul>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Business spending past 3, 6 and 12 months
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Identify the largest outgoing payments
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Identify outgoing tax payments
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Monitor salary costs
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              And more
                            </li>
                          </ul>
                          <Link to="/contact-us">
                            <button className="defaultBtn white-bg">Get in touch</button>
                          </Link>
                        </div>
                        <div className="col-md-7">
                          <figure>
                            <img
                              src={spendingBehavior}
                              alt="spendingBehavior"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>

                <TabPanel>
                  <div id="corporate-assets" className="">
                    <div className="width-1200">
                      <div className="row">
                        <div className="col-md-12">
                          <h1 className="h1-basic01">
                            Business
                            <br />
                            assets
                          </h1>
                          <h3 className="h3-basic02">
                            Business Account Certificate
                          </h3>
                          <h6 className="h6-basic03">
                            The asset side is just as important. Get details
                            about the current balances and total assets for the
                            business to gain assurance before making that deal.
                          </h6>
                        </div>
                      </div>
                      <div className="row custom-insights">
                        <div className="col-md-5">
                          <h2 className="h2-basic02">
                            Gives you the following insights
                          </h2>
                          <ul>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Current account balances
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Current bank savings (cash, mutual funds, stocks)
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              And more
                            </li>
                          </ul>
                          <Link to="/contact-us">
                            <button className="defaultBtn white-bg">Get in touch</button>
                          </Link>
                        </div>
                        <div className="col-md-7">
                          <figure>
                            <img
                              src={assetsAccumultion}
                              alt="assetsAccumultion"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>

                <TabPanel>
                  <div id="corporate-debt" className="">
                    <div className="width-1200">
                      <div className="row">
                        <div className="col-md-12">
                          <h1 className="h1-basic01">
                            Business
                            <br />
                            debt
                          </h1>
                          <h3 className="h3-basic02">
                            Business Account Certificate
                          </h3>
                          <h6 className="h6-basic03">
                            Get insights into the crucial details about business
                            debt. Which loans and mortgages have been paid out
                            the last 12 months, and moreover which have been
                            repaid.
                          </h6>
                        </div>
                      </div>
                      <div className="row custom-insights">
                        <div className="col-md-5">
                          <h2 className="h2-basic02">
                            Gives you the following insights
                          </h2>
                          <ul>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Identify paid out loans & mortgages
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Identify loan & mortgage repayments
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Credit card spending patterns
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              And more
                            </li>
                          </ul>
                          <Link to="/contact-us">
                            <button className="defaultBtn white-bg">Get in touch</button>
                          </Link>
                        </div>
                        <div className="col-md-7">
                          <figure>
                            <img src={debtInvolvement} alt="debtInvolvement" />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>

                <TabPanel>
                  <div id="credit-decisioning02" className="">
                    <div className="width-1200">
                      <div className="row">
                        <div className="col-md-12">
                          <h1 className="h1-basic01">
                            Credit
                            <br />
                            decisioning
                          </h1>
                          <h3 className="h3-basic02">
                            Business Account Certificate
                          </h3>
                          <h6 className="h6-basic03">
                            Set your own knock-out rules and scoring variables
                            based on the insights from the complete Kreditz
                            Business Certificate with Kreditz Decisioning
                            Engine.
                          </h6>
                        </div>
                      </div>
                      <div className="row custom-insights">
                        <div className="col-md-5">
                          <h2 className="h2-basic02">
                            Gives you the following insights
                          </h2>
                          <ul>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Customizable scoring indicators
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Increase lending with no change in risk
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Early detection of frauds & defaults
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              And much more
                            </li>
                          </ul>
                          <Link to="/contact-us">
                            <button className="defaultBtn white-bg">Get in touch</button>
                          </Link>
                        </div>
                        <div className="col-md-7">
                          <figure>
                            <img
                              src={creditDecisioning}
                              alt="creditDecisioning"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>

                <TabPanel>
                  <div id="custom-products01" className="">
                    <div className="width-1200">
                      <div className="row">
                        <div className="col-md-12">
                          <h1 className="h1-basic01">
                            Custom
                            <br />
                            products
                          </h1>
                          <h3 className="h3-basic02">
                            Business Account Certificate
                          </h3>
                          <h6 className="h6-basic03">
                            We saved the best for last – the Kreditz Certificate
                            is fully customizable to your specific needs. Let us
                            know what you need and we’ll build it for you in a
                            snap.
                          </h6>
                        </div>
                      </div>
                      <div className="row custom-insights">
                        <div className="col-md-5">
                          <h2 className="h2-basic02">
                            Gives you the following insights
                          </h2>
                          <ul>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Customizable modules
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Industry specific adjustments
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Proven technology
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Speedy delivery
                            </li>
                          </ul>
                          <Link to="/contact-us">
                            <button className="defaultBtn white-bg">Get in touch</button>
                          </Link>
                        </div>
                        <div className="col-md-7">
                          <figure>
                            <img
                              src={verificationMockup}
                              alt="verificationMockup"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </div>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}
export default CorporateProduct;
