import React, { Component } from 'react';
import { StateContext } from '../Store/state-context';
import './Cookie.css';
class Cookie extends Component{
  static contextType = StateContext;
    render(){
      let bannerPadding = "";
    if(this.context.isBanner === true){
      bannerPadding = "banner-padding-cookie"
    }
    else{
      bannerPadding = ""
    }
      return(
          <div className="main-section  main-body-bg">
              <div className={`${bannerPadding}`}>
              <div className="cookie-container">
                <h1 className="cookie-privacy-title h1-basic01 tc-head">Cookie Policy</h1>
                <br/>
                  <table className="cookie-cookie cookie-table">
                    <thead className='cookie-thead'>
                        <tr>
                        <th className="cookie-th">Name</th>
                        <th className="cookie-th">What is the data used for</th>
                        <th className="cookie-th">Who is the data controller</th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr className="cookie-tr">
                        <td className="cookie-td">Google Optimize</td>
                        <td className="cookie-td">Google Optimize offers A/B testing, website testing & personalization tools for small and large businesses.</td>
                        <td className="cookie-td">For information on how to access, manage, or delete cookies and similar technologies used by Google visits. <a href="https://policies.google.com/privacy?hl=en" className="cookie-cookie-link-tag" target="_blank">Read more</a></td>
                      </tr>
                        <tr className="cookie-tr">
                        <td className="cookie-td">Google Tag Manager</td>
                        <td className="cookie-td">Manages JavaScript and HTML tags used for tracking and analysis.</td>
                        <td className="cookie-td">For information on how to access, manage, or delete cookies and similar technologies used by Google visits. <a href="https://policies.google.com/privacy?hl=en" className="cookie-cookie-link-tag" target="_blank">Read more</a></td>
                      </tr>
                        <tr className="cookie-tr">
                        <td className="cookie-td">Google Analytics</td>
                        <td className="cookie-td">Web analytics service that tracks and reports website traffic.</td>
                        <td className="cookie-td">You can use the ad settings to manage the <a href="https://adssettings.google.com/authenticated?hl=en" className="cookie-cookie-link-tag" target="_blank">Google ads</a> you see and opt out of personalized advertising. Even if you opt out of personalized advertising, you may still see ads based on factors such as your general location derived from your IP address, your browser type, and your search terms. You can also manage many companies' cookies used for online advertising through consumer choice tools created under self-regulatory programs in many countries, such as the US-based <a href="http://optout.aboutads.info/?lang=EN&c=2#!%2F" className="cookie-cookie-link-tag" target="_blank">About Ads</a> or the EU-based <a href="http://www.youronlinechoices.com/se/dina-val" className="cookie-cookie-link-tag" target="_blank">Your Online Choices</a> Lastly, you can manage cookies in your browser. <a href="https://policies.google.com/technologies/managing?hl=en" className="cookie-cookie-link-tag" target="_blank">Manage cookies</a> For more information on this, please visit: <a href="https://policies.google.com/technologies/ads?hl=en" className="cookie-cookie-link-tag" target="_blank">Google policies</a></td>
                      </tr>
                      <tr className="cookie-tr">
                        <td className="cookie-td">Hotjar</td>
                        <td className="cookie-td">Product experience insights platform that helps with behavior analytics and feedback data to empathize with and understand customers.</td>
                        <td className="cookie-td">When Hotjar is being used to record and collect data from a site, Hotjar takes on the role of the Data Processor. This means Hotjar is processing data on our behalf whereas we are the owner and Controller of the data. As the Data Controller, we are principally responsible for (among other things) collecting consent, managing consent-revoking and enabling right to access. Hotjar's <a href="https://www.hotjar.com/legal/support/dpa/" className="cookie-cookie-link-tag" target="_blank">Data Processing Agreement</a> specifies the obligations of both parties, us the Controller and Hotjar the Processor.</td>
                      </tr>
                      <tr className="cookie-tr">
                        <td className="cookie-td">LinkedIn Insight Tag</td>
                        <td className="cookie-td">The LinkedIn Insight Tag enables in-depth campaign reporting and unlocks valuable insights about website visitors.</td>
                        <td className="cookie-td">For information on how to access, manage, or delete cookies and similar technologies used by LinkedIn, please visit the LinkedIn Cookie Policy. <a href="https://www.linkedin.com/legal/cookie-policy" className="cookie-cookie-link-tag" target="_blank">Read more</a></td>
                      </tr>
                      <tr>
                        <td className="cookie-td">HubSpot Analytics</td>
                        <td className="cookie-td">HubSpot Analytics tracks website traffic and user behavior to optimize marketing efforts.</td>
                        <td className="cookie-td">You can manage your cookie preferences and opt out of tracking through the HubSpot cookie settings. For more information, visit the HubSpot Cookie Policy. <a href="https://legal.hubspot.com/cookie-policy" className="cookie-cookie-link-tag" target="_blank">Read more</a></td>
                      </tr>
                    </tbody>
                 </table>
                 </div>
            </div>
          </div>
        );
    }
  }
  export default Cookie;