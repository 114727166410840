import React, { Component } from "react";
import "./BannerInfo.css";

class BannerInfo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="alert-details">
        <div className="width-1200">
          <h6>2022-01-12</h6>
          <h1>Se upp för bluff SMS!</h1>
          <p>Just nu cirkulerar falska SMS-utskick som utger sig att komma från Kreditz (eller “Krediz”) och vanligtvis handlar om att du blivit utvald till ett erbjudande eller om spelskulder där du ombeds klicka på en “falsk-länk”. Klicka INTE på länken, vi på Kreditz skickar aldrig SMS på eget bevåg till privatpersoner utan enbart via andra företag vi samarbetar med. Kreditz erbjuder varken lån eller andra tjänster till privatpersoner, utan enbart tjänster till företag.</p>
          <p>Vid minsta tveksamhet på om SMSet du fått är bedrägeri är du välkommen att kontakta oss på Kreditz (<a href="mailto:support@kreditz.com">support@kreditz.com</a>) eller det företag du varit i kontakt med. Om du har blivit utsatt för bedrägeri är det viktigt att du polisanmäler händelsen.</p>

          <h1> Watch out for scam SMS!</h1>
          <p>Right now, fake SMS messages are circulating that pretend to be from Kreditz (or “Krediz”). They usually state that you have been selected for an offer or mention gambling debts where you are asked to click on a “fake link”. DO NOT click on the link, we at Kreditz never send SMS on our own initiative to private individuals, only via other companies we collaborate with. Kreditz offers neither loans or other services to private individuals, but only services to other businesses.</p>
          <p>If you are worried that the SMS you have received is a potential fraud, you are welcome to contact us at Kreditz (<a href="mailto:support@kreditz.com">support@kreditz.com</a>) or the company you have been in contact with. If you have been the victim of fraud, it is important that you report the incident to the police.</p>
        </div>
      </div>
    );
  }
}
export default BannerInfo;
