import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './assets/stylesheets/custom.css';
import './assets/stylesheets/responsive.css';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Routes from './routes';
import {BrowserRouter} from 'react-router-dom';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import ScrollToTop from './Components/ScrollToTop';
import StateContextProvider from './Components/Store/state-context';

console.log(''); // For some reason this allows the page to load if "Marketing" consent is not given, PLEASE DO NOT REMOVE
ReactDOM.render(

  <React.StrictMode>
    <BrowserRouter>
     <StateContextProvider>
      <div id="wrapper">
        <div className="outer-background"></div>
        <Header/>
        <div className="main-body">
          <ScrollToTop>
            <Routes/>
          </ScrollToTop>
        </div>
        <Footer/>
      </div>
     </StateContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();