import React, { Component } from 'react';
import InformationSection from './Components/InformationSection/InformationSection';
import $ from 'jquery';
import './assets/javascripts/slick';

class App extends Component {
  componentDidMount(){
    $('.testimonials-logs').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000,
      arrows: true,
      dots: false,
      pauseOnHover: true
    });
  }
  render(){

    return (
      <div>
      <InformationSection /> 
      </div>    
    );
  }
}

export default App;
