import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { StateContext } from '../Store/state-context';


import "react-tabs/style/react-tabs.css";
import "./ConsumerProduct.css";
import arrowRightGraySmoll from "../../assets/images/arrow_right_gray_smoll.png";
import incomeVerification from "../../assets/images/customer_certificate/income_verification.png";
import spendingBehavior from "../../assets/images/customer_certificate/spending_behavior.png";
import assetsAccumultion from "../../assets/images/customer_certificate/assets_accumultion.png";
import debtInvolvement from "../../assets/images/customer_certificate/debt_involvement.png";
import creditDecisioning from "../../assets/images/customer_certificate/credit_decisioning.png";
import customProduct from "../../assets/images/customer_certificate/custom_product.png";
import fraudDetect from "../../assets/images/customer_certificate/fraud_detect.png";

class ConsumerProduct extends Component {
  constructor() {
    super();
    this.state = { tabIndex: 0 };
  }

  static contextType = StateContext;

  componentDidMount() {
    const para = queryString.parse(this.props.location.search);
    this.setState({ tabIndex: parseInt(para.tab) - 1 });
  }

  render() {
    let bannerPadding = "";

    if (this.context.isBanner === true) {
      bannerPadding = "banner-padding-products"
    }
    else {
      bannerPadding = ""
    }
    return (
      <div className="main-section main-body-bg">
        <div className="tab-list-view verification-tab-list-view">
          <div className="width-1200">
            <Tabs
              selectedTabClassName="tab-active-border"
              selectedIndex={this.state.tabIndex || 0}
              onSelect={(tabIndex) => this.setState({ tabIndex })}
            >
              <TabList className={`prods-tabs ${bannerPadding}`}>
                <Tab value="1" key="1" className="income-verification">
                  <Link to="/consumer-product?tab=1">Income verification</Link>
                </Tab>
                <Tab value="2" key="2" className="spending-behavior">
                  <Link to="/consumer-product?tab=2">Spending behavior</Link>
                </Tab>
                <Tab value="3" key="3" className="asset-accumulation">
                  <Link to="/consumer-product?tab=3">Asset accumulation</Link>
                </Tab>
                <Tab value="4" key="4" className="debt-involvement">
                  <Link to="/consumer-product?tab=4">Debt involvement</Link>
                </Tab>
                <Tab value="5" key="5" className="credit-decisioning01">
                  <Link to="/consumer-product?tab=5">Credit decisioning</Link>
                </Tab>
                <Tab value="6" key="6" className="custom-products">
                  <Link to="/consumer-product?tab=6">Custom products</Link>
                </Tab>
                <Tab value="7" key="7" className="fraud-detect">
                  <Link to="/consumer-product?tab=7">Fraud detect</Link>
                </Tab>
              </TabList>
              <div className="tab-list-details verification-tab-list-details">
                <TabPanel>
                  <div id="income-verification" className="">
                    <div className="width-1200">
                      <div className="row">
                        <div className="col-md-12">
                          <h1 className="h1-basic01">
                            Income
                            <br />
                            verification
                          </h1>
                          <h3 className="h3-basic02">
                            Consumer Account Certificate
                          </h3>
                          <h6 className="h6-basic03">
                            With the Kreditz Certificate{" "}
                            <i>Income verification</i> you can easily identify
                            primary and secondary income streams, employer
                            information and much more. No more manual use of
                            high-risk documents (pay slips, employment
                            contracts, screenshots)
                          </h6>
                        </div>
                      </div>
                      <div className="row custom-insights">
                        <div className="col-md-5">
                          <h2 className="h2-basic02">
                            Gives you the following insights
                          </h2>
                          <ul>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Primary income past 3, 6 and 12 months
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Secondary income past 3, 6 and 12 months
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Employer name and key financial data
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Salary burn-rate
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Secondary income sources
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              And much more
                            </li>
                          </ul>
                          <Link to="/contact-us">
                            <button className="defaultBtn white-bg">Get in touch</button>
                          </Link>
                        </div>
                        <div className="col-md-7">
                          <figure>
                            <img
                              src={incomeVerification}
                              alt="incomeVerification"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div id="spending-behavior" className="">
                    <div className="width-1200">
                      <div className="row">
                        <div className="col-md-12">
                          <h1 className="h1-basic01">
                            Spending <br />
                            behavior
                          </h1>
                          <h3 className="h3-basic02">
                            Consumer Account Certificate
                          </h3>
                          <h6 className="h6-basic03">
                            The Kreditz Certificate helps you to understand the{" "}
                            <i>Spending behavior</i> of your clients. Identify
                            riskful outgoing payments to lenders, betting
                            companies and cash withdrawals or find opportunities
                            when assessing your clients.
                          </h6>
                        </div>
                      </div>
                      <div className="row custom-insights">
                        <div className="col-md-5">
                          <h2 className="h2-basic02">
                            Gives you the following insights
                          </h2>
                          <ul>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Spending patterns past 3, 6 and 12 months
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Month-by-month spending detailed view
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Spending per main & sub category
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Detect riskful spending patterns
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              And much more
                            </li>
                          </ul>
                          <Link to="/contact-us">
                            <button className="defaultBtn white-bg">Get in touch</button>
                          </Link>
                        </div>
                        <div className="col-md-7">
                          <figure>
                            <img
                              src={spendingBehavior}
                              alt="spendingBehavior"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div id="asset-accumulation" className="">
                    <div className="width-1200">
                      <div className="row">
                        <div className="col-md-12">
                          <h1 className="h1-basic01">
                            Asset
                            <br /> accumulation
                          </h1>
                          <h3 className="h3-basic02">
                            Consumer Account Certificate
                          </h3>
                          <h6 className="h6-basic03">
                            Find out what type of savings, mutual funds, stocks
                            and pensions your clients are carrying on their
                            accounts when looking into the Kreditz Certificate{" "}
                            <i>Asset Accumulation</i> view
                          </h6>
                        </div>
                      </div>
                      <div className="row custom-insights">
                        <div className="col-md-5">
                          <h2 className="h2-basic02">
                            Gives you the following insights
                          </h2>
                          <ul>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Total savings per bank/institution
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Monthly private savings
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Monthly pension savings
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Detect income generating assets
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              And much more
                            </li>
                          </ul>
                          <Link to="/contact-us">
                            <button className="defaultBtn white-bg">Get in touch</button>
                          </Link>
                        </div>
                        <div className="col-md-7">
                          <figure>
                            <img
                              src={assetsAccumultion}
                              alt="assetsAccumultion"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div id="debt-involvement" className="">
                    <div className="width-1200">
                      <div className="row">
                        <div className="col-md-12">
                          <h1 className="h1-basic01">
                            Debt
                            <br /> involvement
                          </h1>
                          <h3 className="h3-basic02">
                            Consumer Account Certificate
                          </h3>
                          <h6 className="h6-basic03">
                            Knowing what your clients owe to others is crucial
                            but easy to find out. Gain insights to loan payments
                            and repayments, mortgages and credit cards with the
                            Kreditz Certificate <i>Debt involvement</i> module
                          </h6>
                        </div>
                      </div>
                      <div className="row custom-insights">
                        <div className="col-md-5">
                          <h2 className="h2-basic02">
                            Gives you the following insights
                          </h2>
                          <ul>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Paid out loans the past 12 months
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Total detected debts & credit cards
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Monthly loan repayments
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Noteworthy liabilities
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              And much more
                            </li>
                          </ul>
                          <Link to="/contact-us">
                            <button className="defaultBtn white-bg">Get in touch</button>
                          </Link>
                        </div>
                        <div className="col-md-7">
                          <figure>
                            <img src={debtInvolvement} alt="debtInvolvement" />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div id="credit-decisioning01" className="">
                    <div className="width-1200">
                      <div className="row">
                        <div className="col-md-12">
                          <h1 className="h1-basic01">
                            Credit
                            <br /> decisioning
                          </h1>
                          <h3 className="h3-basic02">
                            Consumer Account Certificate
                          </h3>
                          <h6 className="h6-basic03">
                            Set your own knock-out rules and scoring variables
                            based on the insights from the complete Kreditz
                            Certificate with Kreditz Decisioning Engine
                          </h6>
                        </div>
                      </div>
                      <div className="row custom-insights">
                        <div className="col-md-5">
                          <h2 className="h2-basic02">
                            Gives you the following insights
                          </h2>
                          <ul>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Customizable scoring indicators
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Increase lending with no change in risk
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Early detection of frauds & defaults
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              And much more
                            </li>
                          </ul>
                          <Link to="/contact-us">
                            <button className="defaultBtn white-bg">Get in touch</button>
                          </Link>
                        </div>
                        <div className="col-md-7">
                          <figure>
                            <img
                              src={creditDecisioning}
                              alt="creditDecisioning"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div id="custom-products" className="">
                    <div className="width-1200">
                      <div className="row">
                        <div className="col-md-12">
                          <h1 className="h1-basic01">
                            Custom
                            <br /> products
                          </h1>
                          <h3 className="h3-basic02">
                            Consumer Account Certificate
                          </h3>
                          <h6 className="h6-basic03">
                            We saved the best for last – the Kreditz Certificate
                            is fully customizable to your specific needs. Let us
                            know what you need and we’ll build it for you in a
                            snap
                          </h6>
                        </div>
                      </div>
                      <div className="row custom-insights">
                        <div className="col-md-5">
                          <h2 className="h2-basic02">
                            Gives you the following insights
                          </h2>
                          <ul>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Customizable modules
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Industry specific adjustments
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Proven technology
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Speedy delivery
                            </li>
                          </ul>
                          <Link to="/contact-us">
                            <button className="defaultBtn white-bg">Get in touch</button>
                          </Link>
                        </div>
                        <div className="col-md-7">
                          <figure>
                            <img src={customProduct} alt="customProduct" />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div id="fraud-detect" className="">
                    <div className="width-1200">
                      <div className="row">
                        <div className="col-md-12">
                          <h1 className="h1-basic01">
                            Fraud <br />
                            Detect
                          </h1>
                          <h3 className="h3-basic02">
                            Consumer and Business Certificates
                          </h3>
                          <h6 className="h6-basic03">
                            With Kreditz <i>Fraud Detect</i> you can easily identify fraudulent behaviour in consumer and business lending. Kreditz's innovative solution to identify Non-payers makes financial fraud a thing of the past.
                          </h6>
                        </div>
                      </div>
                      <div className="row custom-insights">
                        <div className="col-md-5">
                          <h2 className="h2-basic02">
                            Gives you the following insights
                          </h2>
                          <ul>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Identifies Straight-rollers, Non-payers, and First Payment Defaults
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              A probability of fraudulent intentions is predicted for each application
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Automated "red flag" on straight roller
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Detect fraudulent consumer and business clients
                            </li>
                            <li>
                              <img
                                src={arrowRightGraySmoll}
                                alt="arrowRightGraySmoll"
                              />
                              Combine with Credit scoring, Policy rules, and Affordability calculations in Kreditz Alta
                            </li>
                          </ul>
                          <Link to="/contact-us">
                            <button className="defaultBtn white-bg">Get in touch</button>
                          </Link>
                        </div>
                        <div className="col-md-7">
                          <figure>
                            <img
                              src={fraudDetect}
                              alt="Fraud detect image"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </div>
            </Tabs>
          </div>
        </div >
      </div >
    );
  }
}

export default ConsumerProduct;
