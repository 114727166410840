import React, { Component } from 'react';
import { StateContext } from '../Store/state-context';
import './Booking.css'

class BookADemo extends Component{
  static contextType = StateContext;

  render(){
    let bannerPadding = "";
 
    if(this.context.isBanner === true){
      bannerPadding = "banner-padding-booking"
    }
    else{
      bannerPadding = ""
    }
  
    return(
      <div className="main-section main-body-bg">
        <div className="width-1200">
           <div className="tab-list-view verification-tab-list-view">
             <div className="tab-list-details verification-tab-list-details">
               <div className={`width-1200 ${bannerPadding}`} style={{ margin: 0 }}>
                <div className="row booking-container">
                  <div className="col-md-12" style={{ padding: 0 }} data-cookieconsent="marketing">
                      <iframe
                        title={'hubspot'}
                        style={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        minWidth: '312px',
                        minHeight: '750px',
                        }}
                        src={'https://meetings.hubspot.com/elias-abrahamsson/kreditz-demo?embed=true'}
                        data-hj-allow-iframe="true"
                      />
                  </div>
                </div>
               </div>
             </div>
           </div>
         </div>
      </div>
    )
  }
};

export default BookADemo;