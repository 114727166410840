import React, { Component } from 'react';
import $ from 'jquery';
import givemoney from '../../assets/images/givemoney.svg';
import rightArrowSmallBlue from '../../assets/images/business_lending.svg';
import bill from '../../assets/images/money_transfer.svg';
import diamond from '../../assets/images/online_gambling.svg';
import moneySill from '../../assets/images/auditing.svg';
import cash from '../../assets/images/insurance.svg';
import { StateContext } from '../Store/state-context';

import consumerLending from '../../assets/images/use_cases/consumer_lending.png';
import businessLending from '../../assets/images/use_cases/business_lending.png';
import moneyTransfer from '../../assets/images/use_cases/money_transfer.png';
import onlineGambling from '../../assets/images/use_cases/online_gambling.png';
import auditing from '../../assets/images/use_cases/auditing.png';
import insurance from '../../assets/images/use_cases/insurance.png';
import './UseCases.css';

class UseCases extends Component{

  static contextType = StateContext;

	resetActiveNav = (hash) =>{
		$('.nav-tabs').find('.nav-item.active').removeClass('active');
		$('.tab-content').find('.tab-pane.active').removeClass('active');
		switch (hash) {
			case "#consumer-lending":
				$('.consumer-lending').addClass('active');		
				break;
			case "#business-landing":
				$('.business-lending').addClass('active');		
				break;
			case "#money-transfer":
				$('.money-transfer').addClass('active');
				break;
			case "#online-gambling":
				$('.online-gamling').addClass('active');
				break;
			case "#auditing":
				$('.auditing').addClass('active');
				break;
			case "#insurance":
				$('.insurance').addClass('active');
				break;
			default:
				$('.consumer-lending').addClass('active');
				break;
		}
	}
	componentDidMount(){
		this.resetActiveNav(this.props.location.hash);
	}
  render(){
		this.resetActiveNav(this.props.location.hash);

    let bannerPadding = "";
 
    if(this.context.isBanner === true){
      bannerPadding = "banner-padding-use-cases"
    }
    else{
      bannerPadding = ""
    }
		return(
      <div className="main-section main-body-bg">
        <div className="tab-list-view verification-tab-list-view">
					<div className="width-1200">
						<div className="tab-list-details verification-tab-list-details">
              <div className={`width-1200 ${bannerPadding}`} style={{margin: "0"}}>
								<div className="row">
									<div className="col-md-12" style={{padding: "0"}}>
										<h1 className="h1-basic01">Product use cases</h1>
										<h6 className="h6-basic03 mt-40">Open banking & PSD2 will help in many industries. Here are the most common use cases.</h6>
									</div>
								</div>
							</div>
							<div className="products-tabs row">
								<ul className="col-md-4 nav nav-tabs use-cases-lists" role="tablist">
									<li className={"nav-item consumer-lending"}>
										<a className="nav-link" data-toggle="tab" href="#Income_tab_verification">
											<strong><img src={givemoney} alt="givemoney" /></strong>
											<div className="product-tab-details">
												<h4>Consumer Lending</h4>
												<p>Verify current income, spending, assets & debts in order to identify risk and opportunities.</p>
												<span className="link-rediraction  hide" href="#">View example <img src={rightArrowSmallBlue} alt="rightArrowSmallBlue"/></span>
											</div>
										</a>
									</li>
									<li className={"nav-item business-lending"}>
										<a className="nav-link" data-toggle="tab" href="#spending_tab_behavior">
											<strong><img src={rightArrowSmallBlue} alt="bill"/></strong>
											<div className="product-tab-details">
												<h4>Business Lending</h4>
												<p>Gain full insight of incoming & outgoing payments, tax payments and more to make the right decision.</p>
												<span className="link-rediraction  hide" href="#">View example <img src={rightArrowSmallBlue} alt="rightArrowSmallBlue"/></span>
											</div>
										</a>
									</li>
									<li className={"nav-item money-transfer"}>
										<a className="nav-link" data-toggle="tab" href="#asset_tab_accumulation">
											<strong><img src={bill} alt="diamond" /></strong>
											<div className="product-tab-details">
												<h4>Money transfer</h4>
												<p>KYC & AML assessments have never been easier than now. Identify riskful transaction and gain full compliance.</p>
												<span className="link-rediraction  hide" href="#">View example <img src={rightArrowSmallBlue} alt="rightArrowSmallBlue"/></span>
											</div>
										</a>
									</li>
									<li className={"nav-item online-gamling"}>
										<a className="nav-link" data-toggle="tab" href="#debt_tab_involvement">
											<strong><img src={diamond} alt="moneySill"/></strong>
											<div className="product-tab-details">
												<h4>Online gambling</h4>
												<p>Get to know the source of income and source of wealth of your clients. Move from paper pay slip to digital.</p>
												<span className="link-rediraction  hide" href="#">View example <img src={rightArrowSmallBlue} alt="rightArrowSmallBlue"/></span>
											</div>
										</a>
									</li>
									<li className={"nav-item auditing"}>
										<a className="nav-link" data-toggle="tab" href="#credit_tab_decisioning">
											<strong><img src={moneySill} alt="cash" style={{margin: "4px 10px 0 0"}}/></strong>
											<div className="product-tab-details">
												<h4>Auditing</h4>
												<p>Corporate governance will be done more efficiently and with less time. Auditors and shareholders will be pleased.</p>
												<span className="link-rediraction  hide" href="#">View example <img src={rightArrowSmallBlue} alt="rightArrowSmallBlue"/></span>
											</div>
										</a>
									</li>
									<li className={"nav-item insurance"}>
										<a className="nav-link" data-toggle="tab" href="#insurance">
											<strong><img src={cash} alt="cash" style={{margin: "4px 10px 0 0"}}/></strong>
											<div className="product-tab-details">
												<h4>Insurance</h4>
												<p>Take your proof-of-purchase and claims settlement into the digital era with bank transaction analysis</p>
												<span className="link-rediraction  hide" href="#">View example <img src={rightArrowSmallBlue} alt="rightArrowSmallBlue"/></span>
											</div>
										</a>
									</li>
								</ul>
								<div className="col-md-8 tab-content">
									<div className={"tab-pane consumer-lending"} id="Income_tab_verification" role="tabpanel">
										<img src={consumerLending} alt="consumerLending" />
									</div>
									<div className={"tab-pane business-lending"} id="spending_tab_behavior" role="tabpanel">
										<img src={businessLending} alt="businessLending" />
									</div>
									<div className={"tab-pane money-transfer"} id="asset_tab_accumulation" role="tabpanel">
										<img src={moneyTransfer} alt="moneyTransfer" />
									</div>
									<div className={"tab-pane online-gamling"} id="debt_tab_involvement" role="tabpanel">
										<img src={onlineGambling} alt="onlineGambling" />
									</div>
									<div className={"tab-pane auditing"} id="credit_tab_decisioning" role="tabpanel">
										<img src={auditing} alt="auditing" />
									</div>
									<div className={"tab-pane insurance"} id="insurance" role="tabpanel">
										<img src={insurance} alt="insurance" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
      </div>
    );
  }
}
export default UseCases;