import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logoSvg from '../../assets/images/logo.svg';
import downArrow from '../../assets/images/down_arrow.png';
import lock from '../../assets/images/lock.png';
import menu from '../../assets/images/menuIcon.svg';
import iconScame from '../../assets/images/icon_scame_alert.png';
import iconClose from '../../assets/images/close.png';
import ProductDropdown from './ProductDropdown';
import  './Header.css';
import UserCaseDropdown from './UserCaseDropdown';
import DeveloperDropdown from './DeveloperDropdown';
import AboutDropdown from './AboutDropdown';
import headerImgPng from '../../assets/images/headerImg.png'
import kreditzLogo from '../../assets/images/Kreditz-logo.svg'
import { StateContext } from '../Store/state-context';

class Header extends Component{

  constructor(props){
    super(props)
    this.handleScrollOnTop = this.handleScrollOnTop.bind(this); 
  }
  
  static contextType = StateContext;

  handleScrollOnTop = () => {
    window.scrollTo(0, 0);
  }
  
  render(){
    const { closeBanner } = this.context;

    if(this.context.isBanner === true){
      localStorage.setItem('isBanner', true)
    }
    return(
		<>
		<div className="menuBar">
		{
    		(this.context.isBanner && window && window.location.pathname !== '/alert_information') && (
    			<div id="phone_scams_alert">
					  <div className="inner-phone-scams">
					  	<img src={iconScame} alt="img" className="scame-alert-icon" />
					    <div className="scams-alert">
					      <h2>Se upp för bluff SMS!</h2>
					      <div className="alert-links">
						      <a href="/alert-information" className="read-more-details">Läs mer</a>
						      <a href="javascript:void(0)" className="icon-close-btn"><img onClick={closeBanner} src={iconClose} alt="img" width="11px" /></a>
					    	</div>
					    </div>
					  </div>
					</div>
    		)
    	}
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          {/* <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#kreditznavbar" aria-controls="kreditznavbar" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button> */}
					<div className="menu-bar-icon"><a href="#/"><img src={menu} alt="menu"/></a></div>
          <Link className="navbar-brand" to='/'>
            <img src={kreditzLogo} alt="Kreditz-logo" />
          </Link>
          <div className="navbar-collapse" id="kreditznavbar">
						<div className={(this.context.isBanner && window && window.location.pathname !== '/alert-information') ? "center-side-navigation center-side-navigation-sp" : "center-side-navigation ms-lg-auto"}>
							<ul className="navbar-nav ms-lg-auto mb-2 mb-lg-0 ruby-menu">
								<li className="nav-item ruby-menu-mega">
									<a className="nav-link open-link open-dropdown-bg" aria-current="page" href="#">Products</a>
									<ProductDropdown />
								</li>
								<li className="nav-item ruby-menu-mega">
									<a className="nav-link open-link open-dropdown-bg" href="#">Use Cases</a>
									<UserCaseDropdown />
								</li>
								<li className="nav-item ruby-menu-mega">
									<a className="nav-link open-link open-dropdown-bg" href="#">About us</a>
									<AboutDropdown />
								</li>
								<li className="nav-item ruby-menu-mega">
									<a href="https://careers.kreditz.com/" className="nav-link open-link open-dropdown-bg">Careers</a>
								</li>
							</ul>
						</div>
					</div>
          <form className="d-flex" role="search">
            <Link to="/bookings"><button className="btn btn-primary">Book a demo</button></Link>
          </form>
        </div>
      </nav>
    </div>
	</>
    )
  }
}
export default Header;