import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class ProductDropdown extends Component {
  render() {
    return (
      <div className="full-dropdown">
        <div className="inner-full-dropdown">
          <div className="menu-drop-down">
            <div className="row m-0">
              <div className="col-md-4">
                <h3 className="h3-basic01">Products</h3>
                <p className="menu-para">Our accuracy when it comes to understanding the transaction makes it possible to offer the following standardized and customizable products based on Open Banking- & PSD2-data.<br /><br />All products accessed either by API or through our web portal.</p>
              </div>
              <div className="col-md-4">
                <h4 className="h4-basic01"><a href="/consumer-product?tab=1" className="open_link link-color">Consumer Account Certificate</a></h4>
                <p className="label-menu">
                  <a href="/consumer-product?tab=1" className="open_link link-color">
                    <strong>Income verification</strong>
                    Primary & secondary income streams
                  </a>
                </p>
                <p className="label-menu">
                  <a href="/consumer-product?tab=2" className="open_link link-color">
                    <strong>Spending behavior</strong>
                    A detailed view of how money is spent
                  </a>
                </p>
                <p className="label-menu">
                  <a href="/consumer-product?tab=7" className="open_link link-color">
                    <strong>Fraud detect</strong>
                    Financial security with advanced fraud detection
                  </a>
                </p>
                <p className="label-menu">
                  <a href="/consumer-product?tab=3" className="open_link link-color">
                    <strong>Asset accumulation</strong>
                    Monthly savings & accumulated assets
                  </a>
                </p>
                <p className="label-menu">
                  <a href="/consumer-product?tab=4" className="open_link link-color">
                    <strong>Debt involvement</strong>
                    Paid out loans & repayments
                  </a>
                </p>
                <p className="label-menu">
                  <a href="/consumer-product?tab=5" className="open_link link-color">
                    <strong>Credit decisioning</strong>
                    Customizable feature engineered variables
                  </a>
                </p>
                <p className="label-menu">
                  <a href="/consumer-product?tab=6" className="open_link link-color">
                    <strong>Custom products</strong>
                    Let us know what you need and we’ll make it
                  </a>
                </p>
              </div>
              <div className="col-md-4">
                <h4 className="h4-basic01"><a href="/corporate-product?tab=1" className="open_link link-color">Business Account Certificate</a></h4>
                <p className="label-menu">
                  <a href="/corporate-product?tab=1" className="open_link link-color">
                    <strong>Business revenue</strong>
                    Identify current turnover & clients
                  </a>
                </p>
                <p className="label-menu">
                  <a href="/corporate-product?tab=2" className="open_link link-color">
                    <strong>Business spending</strong>
                    Identify current spending & suppliers
                  </a>
                </p>
                <p className="label-menu">
                  <a href="/corporate-product?tab=3" className="open_link link-color">
                    <strong>Business assets</strong>
                    Balances & total assets
                  </a>
                </p>
                <p className="label-menu">
                  <a href="/corporate-product?tab=4" className="open_link link-color">
                    <strong>Business debt</strong>
                    Use of debt, loans & repayments
                  </a>
                </p>
                <p className="label-menu">
                  <a href="/corporate-product?tab=5" className="open_link link-color">
                    <strong>Credit decisioning</strong>
                    Better decisions with better insights
                  </a>
                </p>
                <p className="label-menu">
                  <a href="/corporate-product?tab=6" className="open_link link-color">
                    <strong>Custom products</strong>
                    You want something done, we’ll do it for you
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ProductDropdown;