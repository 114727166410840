import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import "../../Flags.css";
import logoSvg from "../../assets/images/logo.svg";
import flag from "../../assets/images/flag.png";
import facebook from "../../assets/images/facebook.svg";
import icon_down from "../../assets/images/icon_down.png";
import googleplay from "../../assets/images/googleplay.png";
import appstore from "../../assets/images/appstore.png";
import Linkedin from '../../assets/images/Linkedin.svg'
import Instagram from '../../assets/images/Instagram.svg'
import twitter from '../../assets/images/twitter.svg'



class Footer extends Component {
  constructor(props) {
    super(props);
    this.handleScrollOnTop = this.handleScrollOnTop.bind(this);
  }

  handleScrollOnTop = () => {
    window.scrollTo(0, 0);
  };
  render() {
    let year = new Date().getFullYear();
    return (
      <footer>
        <div className="container">
          <div className="footerDetails">
            <div className="row align-items-center">
              <div className="col-8 col-md-8">
                <div className="copyRight">
                  <p>© Kreditz {year}. All rights reserved.</p>
                  <p>Vendevägen 87 Danderyd, Sweden</p>
                  <p><Link to='/cookie'>Cookie Policy</Link></p>
                </div>
              </div>
              <div className="col-4 col-md-4">
                <div className="socialIcons">
                  <ul>
                    <li><a href="https://www.linkedin.com/company/kreditz/"><img src={Linkedin} alt="Linkedin" /></a></li>
                    <li><a href="https://www.instagram.com/getkreditz/"><img src={Instagram} alt="Instagram" /></a></li>
                    <li><a href="https://twitter.com/getkreditz"><img src={twitter} alt="twitter" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
