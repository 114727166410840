import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import App from './App';
import Plans from './Components/Plans/Plans';
import About from './Components/About/About';
import UseCases from './Components/UseCases/UseCases';
import CorporateProduct from './Components/Products/CorporateProduct';
import ConsumerProduct from './Components/Products/ConsumerProduct';
import ContactUs from './Components/ContactUs/ContactUs';
import TermsCondition from './Components/TermsCondition/TermsCondition';
import PrivacyPolicy from './Components/TermsCondition/PrivacyPolicy';
import Cookie from './Components/TermsCondition/Cookie';
import BannerInfo from './Components/BannerInfo/BannerInfo';
import BookADemo from './Components/Booking/BookADemo';
import GeneralConditions from './Components/TermsCondition/GeneralConditions';
import AccountInformationService from './Components/TermsCondition/AccountInformationService';

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={App} />
        <Route path="/plans" component={Plans} />
        <Route exact path="/consumer_product">
          <Redirect to="/consumer-product" status="301" />
        </Route>
        <Route path="/consumer-product" component={ConsumerProduct} />
        <Route exact path="/corporate_product">
          <Redirect to="/corporate-product" status="301" />
        </Route>
        <Route path="/corporate-product" component={CorporateProduct} />
        <Route path="/about" component={About} />
        <Route exact path="/use_cases">
          <Redirect to="/use-cases" status="301" />
        </Route>
        <Route path="/use-cases" component={UseCases} />
        <Route exact path="/contact_us">
          <Redirect to="/contact-us" status="301" />
        </Route>
        <Route path="/contact-us" component={ContactUs} />
        <Route exact path="/term_and_condition">
          <Redirect to="/terms-and-conditions" status="301" />
        </Route>
        <Route path="/terms-and-conditions" component={TermsCondition} />
        <Route exact path="/privacy_policy">
          <Redirect to="/privacy-policy" status="301" />
        </Route>
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/cookie" component={Cookie} />
        <Route exact path="/alert_information">
          <Redirect to="/alert-information" status="301" />
        </Route>
        <Route path="/alert-information" component={BannerInfo} />
        <Route path="/bookings" component={BookADemo} />
        <Route path="/villkor-kontoinformationstjanst" component={GeneralConditions} />
        <Route path="/kontoinformationstjanster" component={AccountInformationService} />
        {/* <Route path="*" component={App} /> */}
        <Route render={() => <Redirect to={{ pathname: "/" }} status="301" />} />
      </Switch>
    )
  }
};

export default Routes;