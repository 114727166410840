import React, { Component } from 'react';
import graphBar from '../../assets/images/graph-bar.png';
import puzzle from '../../assets/images/puzzle.png';
import puzzle_mobile from '../../assets/images/puzzle_mobile.png';
import graphBar01 from '../../assets/images/graph-bar01.png';
import higherIcon from '../../assets/images/higherIcon.svg'
import reductionIcon from '../../assets/images/reductionIcon.svg'
import processIcon from '../../assets/images/processIcon.svg'
import costIcon from '../../assets/images/costIcon.svg'
import Santander from '../../assets/images/Santander.svg'
import Ikano from '../../assets/images/Ikano.svg'
import DNB from '../../assets/images/DNB.svg'
import Qred from '../../assets/images/Qred.svg'
import CollectorBank from '../../assets/images/Collector-Bank.svg'
import Carousel from "react-simply-carousel";




class KeyBenefits extends Component {
  render() {
    return (
      <div className="card">
        <div className="cardHead">
          <img src={this.props.img} alt={this.props.img} />
          <h3>{this.props.h3}</h3>
        </div>
        <h4>{this.props.h4}</h4>
        <p>
          {this.props.p}
        </p>
      </div>
    )
  }
}

const keyBenefitData = [
  { img: higherIcon, h3: '45%', h4: 'Higher approval rate', p: "Improve your customer's quality in a faster, more secure, and confident way." },
  { img: reductionIcon, h3: '50%', h4: 'Reduction in default rates', p: 'Unlock lower default rates by combining Open Banking and CRA data.' },
  { img: processIcon, h3: '80%', h4: 'Faster decisioning process', p: 'Build automation and make decisions without physical documents.' },
  { img: costIcon, h3: '75%', h4: 'Cost reduction of credit bureau data', p: 'Earlier knock-outs of unfit applicants, only pay for the credit bureau data you really need.' }
]

class RoleEcosystem extends Component {
  constructor(props) {
    super(props);

    // Initialize the activeSlide state
    this.state = {
      activeSlide: 0,
    };
  }

  // Function to update the activeSlide state
  setActiveSlide = (index) => {
    this.setState({ activeSlide: index });
  };

  render() {
    return (
      // <div className="role-ecosystem">
      //   <div className="width-1200">
      //     <div className="row">
      //       <div className="col-md-12">
      //         <h2 className="h2-basic01">Our role in the ecosystem</h2>
      //         <h6 className="h6-basic01">Use your own open banking/PSD2-data aggregator or one of our partners. Even add traditional credit bureau information. We connect all the dots in one easy integration.</h6>
      //       </div>
      //     </div>
      //     <div className="row ecosystem-graph">
      //       <div className="col-md-12">
      //         <figure className="echo-graph">
      //           <img src={puzzle} alt="puzzle" className="img_pc" />
      //           <img src={puzzle_mobile} alt="ourRules" className="img_sp" />
      //         </figure>
      //       </div>
      //     </div>
      //   </div>
      // </div>
      <>
        <section className='keyBenefits'>
          <div className='container'>
            <div className="row">
              <div className="col-12">
                <h2 className="sectionHead">Key benefits</h2>
              </div>
            </div>
            <Carousel
              responsiveProps={[{ maxWidth: 991, itemsToShow: 1 }]}
              containerProps={{
                className: 'keyCarousel' // Add your desired class here
              }}
              preventScrollOnSwipe
              swipeTreshold={60}
              activeSlideIndex={this.state.activeSlide}
              onRequestChange={this.setActiveSlide}
              itemsToScroll={1}
              infinite={true}
              forwardBtnProps={{
                show: false,
              }}
              backwardBtnProps={{
                show: false,
              }}
              dotsNav={{
                show: false,
                itemBtnProps: {
                  style: {
                    height: 16,
                    width: 16,
                    borderRadius: "50%",
                    border: 0
                  }
                },
                activeItemBtnProps: {
                  show: false,
                }
              }}
              autoplay={true}
              itemsToShow={4}
              speed={10000}
            // autoplayInterval={1000}
            // centerMode
            >
              {keyBenefitData.map((item, index) => (
                <div className='kbCard' key={index}>
                  <KeyBenefits img={item.img} h3={item.h3} h4={item.h4} p={item.p} />
                </div>
              ))}
            </Carousel>
          </div>
        </section>


        {/* <section class="keyBenefits">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2 class="sectionHead">Key benefits</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-3">
              <div class="card">
                <div class="cardHead">
                  <img src={higherIcon} alt="higherIcon" />
                  <h3>35%</h3>
                </div>
                <h4>Higher approval <br />rate</h4>
                <p>
                  Improve your customer quality in a faster, more secure, and confident way.
                </p>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="card">
                <div class="cardHead">
                  <img src={reductionIcon} alt="reductionIcon" />
                  <h3>25%</h3>
                </div>
                <h4>Reduction in <br />default rates</h4>
                <p>
                  Unlock lower default rates by combining Open Banking and CRA data.
                </p>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="card">
                <div class="cardHead">
                  <img src={processIcon} alt="processIcon" />
                  <h3>80%</h3>
                </div>
                <h4>Faster decisioning <br />process</h4>
                <p>
                  Build automations and make decisions without physical documents.
                </p>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="card">
                <div class="cardHead">
                  <img src={costIcon} alt="costIcon" />
                  <h3>75%</h3>
                </div>
                <h4>Cost reduction of <br />credit bureau data</h4>
                <p>
                  Earlier knock-outs of unfit applicants, only pay for the credit bureau data you really need.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
        {/* <scetion class="trustedBy">
    <div class="container">
      <h5>Trusted by</h5>
      <ul>
        <li><img src={Santander} alt="Santander" /></li>
        <li><img src={Ikano} alt="Ikano" /></li>
        <li><img src={DNB} alt="DNB" /></li>
        <li><img src={Qred} alt="Qred" /></li>
        <li><img src={CollectorBank} alt="Collector Bank" /></li>
      </ul>
    </div>
  </scetion> */}
      </>
    );
  }
}
export default RoleEcosystem;