import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import arrowRightBlue from '../../assets/images/arrow_right_blue.svg';
import brainBlue from '../../assets/images/brain_large.svg';
import brainSp from '../../assets/images/brain_sp.svg';
import headerImgPng from '../../assets/images/headerImg.png'
import { StateContext } from '../Store/state-context';

class GetInTouchBanner extends Component{
 
  static contextType = StateContext;
  
  render(){
    let bannerPadding = "";
 
    if(this.context.isBanner === true){
      bannerPadding = "banner-padding"
    }
    else{
      bannerPadding = ""
    }
    return(
      // <div className={`get-in-touch-banner ${bannerPadding}`}>
      //   <div className="width-1200">
      //     <div className={`row brain-header ${bannerPadding}`}>
      //       <div className="col-md-7 left-side-get-touch">
      //         <h1>The masterminds<br/>of open banking<br/>& PSD2</h1>
      //         <h6>Understanding and making use of open banking- & PSD2-data is easy with insights from the Kreditz Certificate</h6>
      //         <div className="right-side-buttons">
      //           <Link to="/bookings"><button className="get-start">Book a demo</button></Link>
      //           <Link to="/contact_us"><button className="white-btn">Get in touch</button></Link>
      //           <Link to="/#funnel_set" className="login">Learn more <img src={arrowRightBlue} alt="arrow_right_blue"/></Link>
      //         </div>
      //       </div>
      //       <div className="col-md-5 right-side-brain">
      //         <img className="img_pc" src={brainBlue} alt="brain-blue"/>
      //         <img className="img_sp" src={brainSp} alt="brain-sp"></img>
      //       </div>
      //       <div className="get-in-touch-sp">
      //           <div>
      //             <Link to="/bookings"><button className="get-start">Book a demo</button></Link>
      //             <Link to="/contact_us"><button className="white-btn">Get in touch</button></Link>
      //           </div>
      //           <Link to="/#funnel_set" className="login login-mobile">Learn more <img src={arrowRightBlue} alt="arrow_right_blue"/></Link>
      //       </div>
      //     </div>
      //   </div>
      // </div>
      <header>
    <div className="slider">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <div className="mainHeading">
              <h1>
                Intelligent credit- & risk decisions made easy
              </h1>
              <p>
                Utilize the power of consent-based data & AI-technology to make intelligent decisions
              </p>
              <div className="contactButton">
                <Link to="/bookings"><button className="defaultBtn">Book a demo</button></Link>
                <Link to="/contact_us"><button className="defaultBtn white-bg">Contact sales</button></Link>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="sliderImg">
              <img src={headerImgPng} alt="kreditz report" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
    );
  }
}
export default GetInTouchBanner;