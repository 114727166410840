import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import checkIcon from '../../assets/images/check.png';
import closeIcon from '../../assets/images/close.png';
import { Link } from 'react-router-dom';

class TabListView extends Component{
  render(){
    return(
      <div className="tab-list-view">
        <div className="width-1200">
          <Tabs
              defaultFocus={true}
              selectedTabClassName="tab-active-border"  
            >
            <TabList className="plan-tabs">
              <Tab className="account-aggregation">Consumer Account Certificate</Tab>
              <Tab className="pfm">Business Account Certificate</Tab>
            </TabList>
            <div className="tab-list-details table-bg-1">
              <TabPanel>
                <div id="account-aggregation" className="">
                  <div className="table-responsive custom-table-pc">
                    <table className="custom-table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Starter</th>
                          <th>Enterprise</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Kreditz Categorization Engine</td>
                          <td><img src={checkIcon} alt="checkIcon" /></td>
                          <td><img src={checkIcon} alt="checkIcon" /></td>
                        </tr>
                        <tr>
                          <td className="pr0">Kreditz Consumer Certificate<br/><span className="pr0-font">(Income, Spending, Assets, Debts)</span></td>
                          <td><img src={checkIcon} alt="checkIcon" /></td>
                          <td><img src={checkIcon} alt="checkIcon" /></td>
                        </tr>
                        <tr>
                          <td>Kreditz Descisioning Engine</td>
                          <td><img src={checkIcon} alt="checkIcon" /></td>
                          <td><img src={checkIcon} alt="checkIcon" /></td>
                        </tr>
                        <tr>
                          <td>Service Level Agreement</td>
                          <td><img src={closeIcon} alt="closeIcon" /></td>
                          <td><img src={checkIcon} alt="checkIcon" /></td>
                        </tr>
                        <tr>
                          <td>Implementation services</td>
                          <td><img src={closeIcon} alt="closeIcon" /></td>
                          <td><img src={checkIcon} alt="checkIcon" /></td>
                        </tr>
                        <tr>
                          <td>User data storage</td>
                          <td><img src={closeIcon} alt="closeIcon" /></td>
                          <td><img src={checkIcon} alt="checkIcon" /></td>
                        </tr>
                        <tr>
                          <td>AISP-provider</td>
                          <td><span><i>Optional</i></span></td>
                          <td><span><i>Optional</i></span></td>
                        </tr>
                        <tr>
                          <td>Credit Analytics Services</td>
                          <td><span><i>Optional</i></span></td>
                          <td><span><i>Optional</i></span></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td><Link to="/contact-us" className="btn get-start">Get quote</Link></td>
                          <td><Link to="/contact-us" className="btn get-start">Get quote</Link></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="custom-table-sp">
                    <div class="accordion-container accordion-container-table">
                      <div class="set">
                        <a href="javascript:void(0)">Starter<i class="fa fa-plus"></i></a>
                        <div class="content-accordian">
                          <table class="custom-table">
                            <tbody>
                              <tr>
                                <td>Kreditz Categorization Engine</td>
                                <td><img src={checkIcon} alt="checkIcon" /></td>
                              </tr>
                              <tr>
                                <td className="pr0">Kreditz Consumer Certificate<br/><span className="pr0-font">(Income, Spending, Assets, Debts)</span></td>
                                <td><img src={checkIcon} alt="checkIcon" /></td>
                              </tr>
                              <tr>
                                <td>Kreditz Descisioning Engine</td>
                                <td><img src={checkIcon} alt="checkIcon" /></td>
                              </tr>
                              <tr>
                                <td>Service Level Agreement</td>
                                <td><img src={closeIcon} alt="closeIcon" /></td>
                              </tr>
                              <tr>
                                <td>Implementation services</td>
                                <td><img src={closeIcon} alt="closeIcon" /></td>
                              </tr>
                              <tr>
                                <td>User data storage</td>
                                <td><img src={closeIcon} alt="closeIcon" /></td>
                              </tr>
                              <tr>
                                <td>AISP-provider</td>
                                <td><span><i>Optional</i></span></td>
                              </tr>
                              <tr>
                                <td>Credit Analytics Services</td>
                                <td><span><i>Optional</i></span></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td><Link to="/contact-us" className="btn get-start">Get quote</Link></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="set choose-bg01">
                        <a href="javascript:void(0)">Enterprise<i class="fa fa-plus"></i></a>
                        <div class="content-accordian">
                          <table class="custom-table">
                            <tbody>
                              <tr>
                                <td>Kreditz Categorization Engine</td>
                                <td><img src={checkIcon} alt="checkIcon" /></td>
                              </tr>
                              <tr>
                                <td className="pr0">Kreditz Consumer Certificate<br/><span className="pr0-font">(Income, Spending, Assets, Debts)</span></td>
                                <td><img src={checkIcon} alt="checkIcon" /></td>
                              </tr>
                              <tr>
                                <td>Kreditz Descisioning Engine</td>
                                <td><img src={checkIcon} alt="checkIcon" /></td>
                              </tr>
                              <tr>
                                <td>Service Level Agreement</td>
                                <td><img src={checkIcon} alt="checkIcon" /></td>
                              </tr>
                              <tr>
                                <td>Implementation services</td>
                                <td><img src={checkIcon} alt="checkIcon" /></td>
                              </tr>
                              <tr>
                                <td>User data storage</td>
                                <td><img src={checkIcon} alt="checkIcon" /></td>
                              </tr>
                              <tr>
                                <td>AISP-provider</td>
                                <td><span><i>Optional</i></span></td>
                              </tr>
                              <tr>
                                <td>Credit Analytics Services</td>
                                <td><span><i>Optional</i></span></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td><Link to="/contact-us" className="btn primaryBtn get-start">Get quote</Link></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
            
            <div className="tab-list-details table-bg-2">
              <TabPanel>
                <div id="pfm" className="">
                  <div className="table-responsive custom-table-pc">
                    <table className="custom-table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Starter</th>
                          <th>Enterprise</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Kreditz Categorization Engine</td>
                          <td><img src={checkIcon} alt="checkIcon" /></td>
                          <td><img src={checkIcon} alt="checkIcon" /></td>
                        </tr>
                        <tr>
                          <td className="pr0">Kreditz Business Certificate<br/><span className="pr0-font">(Revenue, Spending, Assets, Debts)</span></td>
                          <td><img src={checkIcon} alt="checkIcon" /></td>
                          <td><img src={checkIcon} alt="checkIcon" /></td>
                        </tr>
                        <tr>
                          <td>Kreditz Descisioning Engine</td>
                          <td><img src={checkIcon} alt="checkIcon" /></td>
                          <td><img src={checkIcon} alt="checkIcon" /></td>
                        </tr>
                        <tr>
                          <td>Service Level Agreement</td>
                          <td><img src={closeIcon} alt="closeIcon" /></td>
                          <td><img src={checkIcon} alt="checkIcon" /></td>
                        </tr>
                        <tr>
                          <td>Implementation services</td>
                          <td><img src={closeIcon} alt="closeIcon" /></td>
                          <td><img src={checkIcon} alt="checkIcon" /></td>
                        </tr>
                        <tr>
                          <td>User data storage</td>
                          <td><img src={closeIcon} alt="closeIcon" /></td>
                          <td><img src={checkIcon} alt="checkIcon" /></td>
                        </tr>
                        <tr>
                          <td>AISP-provider</td>
                          <td><span><i>Optional</i></span></td>
                          <td><span><i>Optional</i></span></td>
                        </tr>
                        <tr>
                          <td>Credit Analytics Services</td>
                          <td><span><i>Optional</i></span></td>
                          <td><span><i>Optional</i></span></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td><Link to="/contact-us" className="btn get-start">Get quote</Link></td>
                          <td><Link to="/contact-us" className="btn get-start">Get quote</Link></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="custom-table-sp">
                    <div class="accordion-container accordion-container-table">
                      <div class="set">
                        <a href="javascript:void(0)">Starter<i class="fa fa-plus"></i></a>
                        <div class="content-accordian">
                          <table class="custom-table">
                            <tbody>
                              <tr>
                                <td>Kreditz Categorization Engine</td>
                                <td><img src={checkIcon} alt="checkIcon" /></td>
                              </tr>
                              <tr>
                                <td className="pr0">Kreditz Business Certificate<br/><span className="pr0-font">(Revenue, Spending, Assets, Debts)</span></td>
                                <td><img src={checkIcon} alt="checkIcon" /></td>
                              </tr>
                              <tr>
                                <td>Kreditz Descisioning Engine</td>
                                <td><img src={checkIcon} alt="checkIcon" /></td>
                              </tr>
                              <tr>
                                <td>Service Level Agreement</td>
                                <td><img src={closeIcon} alt="closeIcon" /></td>
                              </tr>
                              <tr>
                                <td>Implementation services</td>
                                <td><img src={closeIcon} alt="closeIcon" /></td>
                              </tr>
                              <tr>
                                <td>User data storage</td>
                                <td><img src={closeIcon} alt="closeIcon" /></td>
                              </tr>
                              <tr>
                                <td>AISP-provider</td>
                                <td><span><i>Optional</i></span></td>
                              </tr>
                              <tr>
                                <td>Credit Analytics Services</td>
                                <td><span><i>Optional</i></span></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td><Link to="/contact-us" className="btn get-start">Get quote</Link></td>
                              </tr>
                            </tbody> 
                          </table>
                        </div>
                      </div>
                      <div class="set choose-bg01">
                        <a href="javascript:void(0)">Enterprise<i class="fa fa-plus"></i></a>
                        <div class="content-accordian">
                          <table class="custom-table">
                            <tbody>
                              <tr>
                                <td>Kreditz Categorization Engine</td>
                                <td><img src={checkIcon} alt="checkIcon" /></td>
                              </tr>
                              <tr>
                                <td className="pr0">Kreditz Business Certificate<br/><span className="pr0-font">(Revenue, Spending, Assets, Debts)</span></td>
                                <td><img src={checkIcon} alt="checkIcon" /></td>
                              </tr>
                              <tr>
                                <td>Kreditz Descisioning Engine</td>
                                <td><img src={checkIcon} alt="checkIcon" /></td>
                              </tr>
                              <tr>
                                <td>Service Level Agreement</td>
                                <td><img src={checkIcon} alt="checkIcon" /></td>
                              </tr>
                              <tr>
                                <td>Implementation services</td>
                                <td><img src={checkIcon} alt="checkIcon" /></td>
                              </tr>
                              <tr>
                                <td>User data storage</td>
                                <td><img src={checkIcon} alt="checkIcon" /></td>
                              </tr>
                              <tr>
                                <td>AISP-provider</td>
                                <td><span><i>Optional</i></span></td>
                              </tr>
                              <tr>
                                <td>Credit Analytics Services</td>
                                <td><span><i>Optional</i></span></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td><Link to="/contact-us" className="btn primaryBtn get-start">Get quote</Link></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    );
  }
}
export default TabListView;