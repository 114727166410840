import React, { Component } from "react";

export const StateContext = React.createContext();

export default class StateContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = { isBanner: false };
    this.closeBanner = this.closeBanner.bind(this);
  }
  closeBanner = () => {
    this.setState({ isBanner: false });
    localStorage.setItem("isBanner", false);
  };
  render() {
    const { closeBanner } = this;
    return (
      <StateContext.Provider value={{ ...this.state, closeBanner }}>
        {this.props.children}
      </StateContext.Provider>
    );
  }
}
